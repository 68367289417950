import React from 'react'
import './education.css'

const Education = () => {
  return (
    <section class="timeline-section">
      <h5>timeline of education</h5>
      <h2>Education</h2>
	<div class="timeline-items">
		<div class="timeline-item">
			<div class="timeline-dot"></div>
			<div class="timeline-date">DEC 2020- JULY 2024</div>
			<div class="timeline-content">
				<h3>Bachelor of Technology</h3>
				<p>I'm pursuing a <b>Bachelor of Technology</b> in <b>Information Technology</b> Branch from <b>ABES Engineering College</b>, Ghaziabad affiliated with <b>Dr. APJ Abdul Kalam University</b>, Lucknow, UP, India. </p>
			</div>
		</div>
		<div class="timeline-item">
			<div class="timeline-dot"></div>
			<div class="timeline-date">2018</div>
			<div class="timeline-content">
				<h3>Intermediate</h3>
				<p>Pursued <b>intermediate</b> from <b>BNSD Inter College, Kanpur</b> from <b>Mathematics</b> and <b>Science</b> stream and secured <b>77.4 percent</b> in it. </p>
			</div>
		</div>
		<div class="timeline-item">
			<div class="timeline-dot"></div>
			<div class="timeline-date">2016</div>
			<div class="timeline-content">
				<h3>High School</h3>
				<p>Pursued <b>intermediate</b> from <b>Saraswati Gyan Mandir Inter College, Kanpur</b> from <b>Science</b> and <b>Computer Science</b> stream and secured <b>84.6 percent</b> in it. </p>
			</div>
		</div>
	</div>
</section>

  )
}

export default Education
